'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _reactRedux = require('react-redux');

var _reactIntl = require('react-intl');

function defaultSelector(state) {
  return state.intl;
}

var mapStateToProps = function mapStateToProps(state, _ref) {
  var _ref$intlSelector = _ref.intlSelector,
      intlSelector = _ref$intlSelector === undefined ? defaultSelector : _ref$intlSelector;

  var intl = intlSelector(state);
  return _extends({}, intl, {
    key: intl.locale
  });
};

exports.default = (0, _reactRedux.connect)(mapStateToProps)(_reactIntl.IntlProvider);